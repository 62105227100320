import React from 'react';

import './App.css';
import { ChakraProvider, extendTheme} from "@chakra-ui/react"
import { Box, Input, Textarea, Button, Stack, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react"
import { PhoneIcon, CheckIcon } from '@chakra-ui/icons'

import { FaUserAlt, FaBuilding, FaQuestionCircle } from 'react-icons/fa';
import { IoIosMail, IoMdMail, IoMdDocument } from 'react-icons/io'

import TextLoop from "react-text-loop";

import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react"

import axios from 'axios';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"

import ReCAPTCHA from "react-google-recaptcha";

const theme = extendTheme({
  colors: {
    black: '#000000',
    teal: "#00A0B0",
    blue: '#00589B',
    red: "#CF5C78",
    white: '#FFFFFF',
    primary: '#E5F230'
  }
})

const recaptchaRef = React.createRef();

class App extends React.Component {
  formTimeout;
  constructor(props){
    super(props);
    console.log('App props', props);
    var context = {};
    if(props.context){
      context = props.context;
    } else if(typeof window !== 'undefined' && window.reactContext) {
      context = window.reactContext;
    }
    
    this.state = {
      clients: [
        {
          name: 'Knowingo',
          src: '/clients/knowingo.png'
        },
        {
          name: 'Unlimited Productions',
          src: '/clients/unlimited-productions.png'
        }
        ,{
          name: 'Pulsitive',
          src: '/clients/pulsitive.png'
        },{
          name: 'Bright Start Studios',
          src: '/clients/bright-star-studios.png'
        },{
          name: 'Institute for Transformational Development',
          src: '/clients/itd.png'
        }
      ],
      tags: [
        {
          name: 'Concept Creation',
          selected: false
        },
        {
          name: 'Brand Building',
          selected: false
        },{
          name: 'Business Identity',
          selected: false
        },{
          name: 'Product Development',
          selected: false
        },{
          name: 'Growth Strategy',
          selected: false
        },{
          name: 'User Experience',
          selected: false
        },{
          name: 'Company Culture',
          selected: false
        },{
          name: 'Customer Journey',
          selected: false
        },{
          name: 'Storytelling',
          selected: false
        },{
          name: 'Branding & Marketing',
          selected: false
        },{
          name: 'Innovative Solutions',
          selected: false
        },{
          name: 'Project Management',
          selected: false
        },{
          name: 'Software Platforms',
          selected: false
        },{
          name: 'Website Design',
          selected: false
        },{
          name: 'Communication',
          selected: false
        },{
          name: 'Raising Investment',
          selected: false
        },{
          name: 'Business Analysis',
          selected: false
        },{
          name: 'Data Vizualization',
          selected: false
        },{
          name: 'Digital Transformation',
          selected: false
        }
      ],

      businessFormLoading: false,
      teamFormLoading: false,
      formLoading: false,
      isFormModalOpen: false,
      isErrorModalOpen: false,
      modalErrorMessage: '',
      uploadFileName: null,
      uploadFile: null,
      bgSource: this.getBgSource(context),
      recaptchaToken: null
    };
  }

  componentDidMount(){
  }

  getBgSource(context){
    return (context.query.bg ) ? "url('"+context.query.bg+"') !important" : 'url(/ani7.gif)'
  }

  async submitBusinessForm(values, actions){
    if(!this.state.formLoading){
      console.log('submitBusinessForm values', values)
      if(this.formTimeout) clearTimeout(this.formTimeout);
      this.setState(state => state.formLoading = true)
      this.setState(state => state.businessFormLoading = true)
      
      this.formTimeout = setTimeout(() => { this.sendBusinessForm(values) }, 1000);
    }
  }
  async sendBusinessForm(values){
   
    let payload = {
      'g-recaptcha-response': this.state.recaptchaToken,
      name: values.name,
      email: values.email,
      company: values.company,
      message: values.message
    };

    await axios.post('/contact', payload);

    this.setState(state => state.businessFormLoading = false)
    this.setState(state => state.formLoading = false)
    this.setState(state => state.isFormModalOpen = true)
  }
  async submitTeamForm(values, actions){
    if(!this.state.formLoading){
      console.log('submitTeamForm values', values)
      if(this.formTimeout) clearTimeout(this.formTimeout);
      this.setState(state => state.formLoading = true)
      this.setState(state => state.teamFormLoading = true)
      
      this.formTimeout = setTimeout(() => { this.sendTeamForm(values) }, 1000);
    }
  }
  async sendTeamForm(values){
   
    const data = new FormData() 
    data.append('name', values.name)
    data.append('email', values.email)
    data.append('message', values.message)
    data.append('file', this.state.uploadFile)


    try {
      let response = await axios.post('/contact/join', data);
      console.log('POST response', response)
      console.log('POST response.data', response.data)
      console.log('POST response.data.error', response.data.error)

      this.setState(state => state.teamFormLoading = false)
      this.setState(state => state.formLoading = false)

      if(response.data.error){

        var errorMessages = {
          LIMIT_PART_COUNT: 'Too many parts',
          LIMIT_FILE_SIZE: 'File too large',
          LIMIT_FILE_COUNT: 'Too many files',
          LIMIT_FIELD_KEY: 'Field name too long',
          LIMIT_FIELD_VALUE: 'Field value too long',
          LIMIT_FIELD_COUNT: 'Too many fields',
          LIMIT_UNEXPECTED_FILE: 'Unexpected field'
        }

        if(response.data.error == "INVALID_UPLOAD_FILEFORMAT"){
          this.openErrorModal('Supported file types are: .pdf, .txt, .doc and .xps.');
        } else if (response.data.error == "LIMIT_FILE_SIZE"){ 
            this.openErrorModal('The uploaded file must be smaller than 50 MB.');
        } else if(errorMessages[response.data.error]){
            this.openErrorModal(errorMessages[response.data.error]);
        } else {
          this.openErrorModal('Something went wrong');
        }
      } else {
        this.setState(state => state.isFormModalOpen = true)
      }

    } catch(err) {
      console.log('POST ERR', err)
      this.setState(state => state.teamFormLoading = false)
      this.setState(state => state.formLoading = false)
      this.openErrorModal('Something went wrong');
      // INVALID_UPLOAD_FILEFORMAT
    }

    
  }

  changeHandler(e, property){
    this.setState(state => state[property] = e.target.value)
  }

  closeFormModal(){
    this.setState(state => state.isFormModalOpen = false)
  }

  closeErrorModal(){
    this.setState(state => state.isErrorModalOpen = false)
  }

  openErrorModal(errorMessage){
    this.setState(state => state.modalErrorMessage = errorMessage)
    this.setState(state => state.isErrorModalOpen = true)
  }

  onFileChangeHandler(event) {
    let file = event.target.files[0];
    console.log('@onFileChangeHandler', file);
    this.setState(state => state.uploadFileName = file.name)
    this.setState(state => state.uploadFile = file)
  }
  render() {
    

    return (
      <ChakraProvider theme={theme}>

      <Modal isOpen={this.state.isFormModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Message Received!</ModalHeader>
          <ModalCloseButton onClick={this.closeFormModal.bind(this)} />
          <ModalBody>
            Thanks, we'll get in touch with you as soon as possible!
          </ModalBody>

          <ModalFooter>
            <Button bg="primary" color="black" mr={2} onClick={this.closeFormModal.bind(this)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={this.state.isErrorModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Oops!</ModalHeader>
          <ModalCloseButton onClick={this.closeErrorModal.bind(this)} />
          <ModalBody>
            {this.state.modalErrorMessage}
          </ModalBody>

          <ModalFooter>
            <Button bg="primary" color="black" mr={2} onClick={this.closeErrorModal.bind(this)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

        <div id="page">
          <div id="header" className="section parent" >
              <div id="background" style={ {backgroundImage: this.state.bgSource } }></div>
              <a href="/" id="logo">{/**/}</a>
              <div id="title" className="child" >
                  <h1>Transforming <span className="textloop"><TextLoop>
                  <span>challenges</span>
                  <span>opportunities</span>
                  <span>ideas</span>
                  <span>concepts</span>
                </TextLoop></span> into <span className="textloop"><TextLoop>
                  <span>opportunities.</span>
                  <span>ideas.</span>
                  <span>concepts.</span>
                  <span>solutions.</span>
                </TextLoop></span></h1>
              </div>
          </div>
          <div id="tags" className="section">
            <div className="wrap">
              {this.state.tags.map((tag, key) => { 
                return <div className="tag" key={key}>{tag.name}</div>
              })}
            </div>
          </div>
          <div id="contact">
            <div className="wrap">
            <div id="form1" className="form">
                    <div className="form-in">
                      <div className="form-title">
                        Let's make it work. Contact us.
                      </div>
                      <div className="form-description">
                        Fill in the form below and we'll get in touch in a blink of an eye ;-)
                      </div>
                      <div className="form-fields">
                          <Stack spacing={4}>
                            <Formik 
                              onSubmit={async (values, actions) => {
                                const token = await recaptchaRef.current.executeAsync();
                                if(token){
                                  this.setState(state => state.recaptchaToken = token, async () => { 
                                      await this.submitBusinessForm(values, actions)
                                      actions.setSubmitting(false)
                                      actions.resetForm();
                                  })
                                } else {
                                  this.openErrorModal("Invalid Captcha")
                                }
                              }}
                              initialValues={
                                {
                                  name: '',
                                  email: '',
                                  company: '',
                                  message: ''
                                }
                              }
                            >{(props) => { 
                              return <Form>
                                <ReCAPTCHA
                                  ref={recaptchaRef}
                                  size="invisible"
                                  sitekey="6LcIiIQaAAAAAJSbrWCXQUX2dmz3UFeioNcEaun5"
                                  onChange={this.onChange}
                                />
                                <Field name="name">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="black" fontSize="16px"><FaUserAlt/></Box>}
                                        />
                                        <Input isRequired isDisabled={this.state.formLoading} {...field} id="name"  border="none" type="text" placeholder="your name" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="email">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="black" fontSize="16px"><IoMdMail/></Box>}
                                        />
                                        <Input  isRequired isDisabled={this.state.formLoading} {...field} id="email" bg="white" color="blue" border="none" _hover={ {bg: 'white'}} type="text" placeholder="your email" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="message">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.message && form.touched.message}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="black" fontSize="16px"><FaQuestionCircle/></Box>}
                                        />
                                        <Textarea isRequired isDisabled={this.state.formLoading} {...field}  maxLength={140} id="message" bg="white" color="blue" border="none" _hover={ {bg: 'white'}}  type="text" placeholder="topic"></Textarea>
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>
                                <div id="buttonholder">
                                  <Button isLoading={this.state.businessFormLoading} type="submit" bg="black" style={{textTransform:'uppercase', width: 'auto', color: 'white', margin: '0 0 0 0'}} >Send</Button>
                                    <div id="paperplane"></div> 
                                </div>
                              </Form>

                            }}</Formik>
                              
                            </Stack>
                      </div>
                    </div>
                  </div>
            </div>
          </div>

          <div id="clients" className="section">
            <div className="wrap">
              <h2>Some of the companies we worked with</h2>
              <div id="client-list" >
                {this.state.clients.map((client, key) => { 
                  return <div key={key}className="client"><img src={client.src} alt={client.name} /></div>
                })}
              </div>
            </div>
          </div>

          <div id="footer" className="section">
            <div id="copyright">© 2021 Conceptiko B.V.</div>
          </div>
        </div>
      </ChakraProvider>
    );
  }
}

export default App;
